import { BaseRouteRpc, RoleType } from '../../../../../core';
import { ProductsAdminUpdateProductVariantRequestSchema } from './request';
import { ProductsAdminUpdateProductVariantResponseSchema } from './response';
  
/**
* @description
*
*
* Request interface:
* ```
* export interface ProductsAdminUpdateProductVariantRequestSchema extends ContractData {
*     item: Item;
* }
*
* export interface Item {
*     id:               number;
*     color?:           string;
*     deliveryPrice?:   number;
*     dimensions?:      string;
*     fileRules?:       FileRules;
*     productionPrice?: number;
*     sku?:             string;
* }
*
* export interface FileRules {
*     isFileRequired: boolean;
*     rules?:         Rules;
* }
*
* export interface Rules {
*     allowedExtensions: string[];
*     height?:           number;
*     units?:            Units;
*     width?:            number;
* }
*
* export type Units = "px" | "in";
*
* ```
* Response interface:
*
* ```
* export interface ProductsAdminUpdateProductVariantResponseSchema extends ContractData {
* }
*
* ```
*/
export class ProductsAdminUpdateProductVariantRouteRpc extends BaseRouteRpc {
  static method = 'products-admin-update-product-variant';

  static request: ProductsAdminUpdateProductVariantRequestSchema;
  static response: ProductsAdminUpdateProductVariantResponseSchema;

  static role = RoleType.admin;
}
