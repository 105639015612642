import { BaseRouteRpc, RoleType } from '../../../../../core';
import { ProductsProductFindAllForClientRequestSchema } from './request';
import { ProductsProductFindAllForClientResponseSchema } from './response';
  
/**
* @description
*
*
* Request interface:
* ```
* export interface ProductsProductFindAllForClientRequestSchema extends ContractData {
*     categoryId?: number;
*     limit?:      number;
*     page?:       number;
* }
*
* ```
* Response interface:
*
* ```
* export interface ProductsProductFindAllForClientResponseSchema extends ContractData {
*     count:    number;
*     products: Product[];
* }
*
* export interface Product {
*     category:             Category;
*     categoryId:           number;
*     deliveryRestrictions: DeliveryRestriction[];
*     description:          string;
*     id:                   number;
*     mockupPictures:       string[];
*     name:                 string;
*     pictures:             string[];
*     variants:             Variant[];
* }
*
* export interface Category {
*     id:    number;
*     title: string;
* }
*
* export interface DeliveryRestriction {
*     active:      boolean;
*     description: string;
*     id:          number;
*     name:        string;
*     payload:     Payload;
*     type:        string;
* }
*
* export interface Payload {
*     postTypes?: string[];
*     states?:    string[];
* }
*
* export interface Variant {
*     color:           string;
*     deliveryPrice:   number;
*     dimensions:      string;
*     fileRules:       FileRules;
*     id:              number;
*     productionPrice: number;
*     sku:             string;
* }
*
* export interface FileRules {
*     isFileRequired: boolean;
*     rules?:         Rules;
* }
*
* export interface Rules {
*     allowedExtensions: string[];
*     height?:           number;
*     units?:            Units;
*     width?:            number;
* }
*
* export type Units = "px" | "in";
*
* ```
*/
export class ProductsProductFindAllForClientRouteRpc extends BaseRouteRpc {
  static method = 'products-product-find-all-for-client';

  static request: ProductsProductFindAllForClientRequestSchema;
  static response: ProductsProductFindAllForClientResponseSchema;

  static role = RoleType.guest;
}
