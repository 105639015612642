import { BaseRouteRpc, RoleType } from '../../../../../core';
import { ProductsProductFindBySkuForCorrectionRequestSchema } from './request';
import { ProductsProductFindBySkuForCorrectionResponseSchema } from './response';
  
/**
* @description
*
*
* Request interface:
* ```
* export interface ProductsProductFindBySkuForCorrectionRequestSchema extends ContractData {
*     sku: string;
* }
*
* ```
* Response interface:
*
* ```
* export interface ProductsProductFindBySkuForCorrectionResponseSchema extends ContractData {
*     product: Product;
* }
*
* export interface Product {
*     name?:     string;
*     pictures?: string[];
*     variant?:  Variant;
* }
*
* export interface Variant {
*     color:           string;
*     deliveryPrice:   number;
*     dimensions:      string;
*     fileRules:       FileRules;
*     id:              number;
*     productionPrice: number;
*     sku:             string;
* }
*
* export interface FileRules {
*     isFileRequired: boolean;
*     rules?:         Rules;
* }
*
* export interface Rules {
*     allowedExtensions: string[];
*     height?:           number;
*     units?:            Units;
*     width?:            number;
* }
*
* export type Units = "px" | "in";
*
* ```
*/
export class ProductsProductFindBySkuForCorrectionRouteRpc extends BaseRouteRpc {
  static method = 'products-product-find-by-sku-for-correction';

  static request: ProductsProductFindBySkuForCorrectionRequestSchema;
  static response: ProductsProductFindBySkuForCorrectionResponseSchema;

  static role = RoleType.unverified;
}
